import React, { useRef, useState, useEffect } from 'react'
import { Button, Card, CardBody, CardTitle, CardHeader, Col, Container, ListGroup, ListGroupItem, Modal, ModalBody, ModalFooter, Row, ModalHeader } from 'reactstrap';
import Breadcrumbs from "../../components/Common/Breadcrumb";
import SimpleBar from 'simplebar-react';
import { Link } from 'react-router-dom';
import List from 'list.js';
import Flatpickr from "react-flatpickr";
import { ThreeCircles } from 'react-loader-spinner'

import firebase from "firebase/compat/app";
import "firebase/firestore";
import "firebase/auth";
import 'firebase/storage';
import {serverTimestamp} from 'firebase/firestore';
import { getDownloadURL, uploadBytesResumable, uploadBytes, getStorage, ref } from "firebase/storage";

const ReCatches = () => {
    
    const storage = getStorage();
    
    const [initialLoad, setInitialLoad] = useState(true);
    const [reCatchesLedger, setReCatchesLedger] = useState([]);
    const [fetchedData, setFetchedData] = useState(false);
    const [isUploading, setIsUploading] = useState(false);
    const [allSpecies, setAllSpecies] = useState([]);
    const [allTagPrograms, setAllTagPrograms] = useState([]);
    
    //Action buttons
    const [isUpdating, setIsUpdating] = useState(false);
    const [updateCatchId, setUpdateCatchId] = useState('');
    const [updateCatchIndex, setUpdateCatchIndex] = useState(null);
    const [updateTagProgramId, setUpdateTagProgramId] = useState('');
    const [deleteCatchId, setDeleteCatchId] = useState('');
    const [deleteCatchIndex, setDeleteCatchIndex] = useState(null);
    const [isDeleting, setIsDeleting] = useState(false);
    const [updatedImage, setUpdatedImage] = useState(null);
    
    const [isRestoringRecatch, setIsRestoringRecatch] = useState(false);
    const [restoreCatchId, setRestoreCatchId] = useState('');
    const [restoreCatchIndex, setRestoreCatchIndex] = useState(null);
    
    document.title = "Catch Ledger | Fish-Trak - Internal Management Dashboard";

    const [modal_list, setmodal_list] = useState(false);
    function tog_list() {
        setmodal_list(!modal_list);
    }
    
    const [deleteModal, setDeleteModal] = useState(false);
    function toggleDeleteModal(catchId, catchIndex) {
        
        if(deleteModal) {
            setDeleteCatchId('');
            setDeleteCatchIndex(null);
        }
        else {
            setDeleteCatchId(catchId);
            setDeleteCatchIndex(catchIndex);
        }
        
        setDeleteModal(!deleteModal);
        
    }
    
    const [restoreRecatchModal, setRestoreRecatchModal] = useState(false);
    function toggleRestoreRecatchModal(catchId, catchIndex) {
        
        if(restoreRecatchModal) {
            setRestoreCatchId('');
            setRestoreCatchIndex(null);
        }
        else {
            setRestoreCatchId(catchId);
            setRestoreCatchIndex(catchIndex);
        }
        
        setRestoreRecatchModal(!restoreRecatchModal);
        
    }
    
    const deleteCatch = async () => {
        
        setIsDeleting(true);
        
        const userRef = firebase.firestore().collection('users').doc(reCatchesLedger[deleteCatchIndex].user.id);
        const tagRef = firebase.firestore().collection('tags').doc(reCatchesLedger[deleteCatchIndex].tag.id);
        const catchRef = firebase.firestore().collection('catches').doc(deleteCatchId);  
        
        if(reCatchesLedger[deleteCatchIndex].recatch) {
            catchRef
              .update({
                  updated_at: serverTimestamp(),
                  deleted: true
                })
              .then(() => {
                tagRef
                    .update({
                        updated_at: serverTimestamp(),
                        catch_count: firebase.firestore.FieldValue.increment(-1),
                        latest_anglers: firebase.firestore.FieldValue.arrayRemove(userRef)
                      })
                    .then((tagResponse) => {
                        
                        userRef
                        .update({
                            updated_at: serverTimestamp(),
                            recatch_count: firebase.firestore.FieldValue.increment(-1)
                          })
                        .then((userResponse) => {
                            
                          fetchReCatchesLedger();
                          alert('The re-catch has been deleted successfully!');
                          setDeleteCatchId('');
                          setDeleteCatchIndex(null);
                          
                        })
                        .catch((error) => {
                          setIsDeleting(false);
                          alert('Could not update user status. Please try again.');
                        }); 
    
                      
                    })
                    .catch((error) => {
                      setIsDeleting(false);
                      alert('Could not update tag status. Please try again.');
                    });  
                    
              })
              .catch((error) => {
                setIsDeleting(false);
                alert('Could not delete re-catch entry. Please try again.');
              });
        }
            
    };
    
    const restoreRecatch = async () => {
        
        setIsRestoringRecatch(true);
                
        const userRef = firebase.firestore().collection('users').doc(reCatchesLedger[restoreCatchIndex].user.id);
        const tagRef = firebase.firestore().collection('tags').doc(reCatchesLedger[restoreCatchIndex].tag.id);
        const catchRef = firebase.firestore().collection('catches').doc(restoreCatchId);  
        
        if(reCatchesLedger[restoreCatchIndex].recatch) {
            catchRef
              .update({
                updated_at: serverTimestamp(),
                deleted: false
              })
              .then(() => {
                tagRef
                    .update({
                        updated_at: serverTimestamp(),
                        catch_count: firebase.firestore.FieldValue.increment(1),
                        latest_anglers: firebase.firestore.FieldValue.arrayUnion(userRef)
                      })
                    .then((tagResponse) => {
                        
                        userRef
                        .update({
                            updated_at: serverTimestamp(),
                            recatch_count: firebase.firestore.FieldValue.increment(1)
                          })
                        .then((userResponse) => {
                            
                          fetchReCatchesLedger();
                          alert('The recatch has been restored successfully!');
                          setRestoreCatchId('');
                          setRestoreCatchIndex(null);
                          
                        })
                        .catch((error) => {
                          setIsRestoringRecatch(false);
                          alert('Could not update user status. Please try again.');
                        }); 
    
                      
                    })
                    .catch((error) => {
                      setIsRestoringRecatch(false);
                      alert('Could not update tag status. Please try again.');
                    });  
                    
              })
              .catch((error) => {
                setIsRestoringRecatch(false);
                alert('Could not restore recatch entry. Please try again.');
              });
        }
            
    };

    const [editModal, setEditModal] = useState(false);
    function toggleEditModal(catchId, catchIndex) {
        
        if(editModal) {
            setUpdateCatchId('');
            setUpdateCatchIndex(null);
        }
        else {
            getUpdateTag(catchId, catchIndex);
            setUpdateCatchId(catchId);
            setUpdateCatchIndex(catchIndex);
        }
        
        setEditModal(!editModal);
    }
    
    const submitUpdateForm = async (event) => {
    
        setIsUploading(true);
        event.preventDefault();
        
        setIsUpdating(true);
        
        if(updatedImage) {
            updateImage(event);
        }
        else {
            updateCatch(event);
        }
    
    }
    
    const updateImage = async (event) => {
        
        event.preventDefault();
        
        const catchTimestamp = event.target.catch_datetime.value.replace(/[^0-9-]/g, '-');
        const catchUserId = reCatchesLedger[updateCatchIndex].user.id;
        const catchTagNumber = event.target.tag_number.value.replace(/[^0-9a-zA-Z-+.:@%&#, ]/g, '');
            
      const imageFileName = '/catch-images/' + catchTagNumber + '-' + catchUserId + '-' + catchTimestamp;
      
      const storageRef = ref(storage, imageFileName);
    
      const uploadTask = uploadBytesResumable(storageRef, updatedImage);
      uploadTask.on(
        "state_changed",
        (snapshot) => {
          const progress =
            (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
          console.log("upload is" + progress + "% done");
          switch (snapshot.state) {
            case "paused":
              console.log("Upload paused");
              break;
            case "running":
              console.log("Upload running");
              break;
            default:
              break;
          }
        },
        (error) => {
          console.log(error);
          setIsUpdating(false);
            alert('Could not update catch image. Please try again.');
        },
        () => {
          getDownloadURL(uploadTask.snapshot.ref).then((downloadedURL) => {
            updateCatch(event, downloadedURL);
          });
        }
      );
    };
    
    const updateCatch = async (event, downloadedURL) => {
        
        const speciesId = event.target.fish_species.value;
        const speciesIndex = event.target.fish_species.selectedIndex - 1;
        const catchTimestampFirebase = firebase.firestore.Timestamp.fromDate(new Date(event.target.catch_datetime.value));
        
      //Create object of program data
        const catchData = {
          updated_at: serverTimestamp(),
        };
                
        if(event.target.catch_name.value != reCatchesLedger[updateCatchIndex].name) {
            catchData['name'] = event.target.catch_name.value.replace(/[^0-9a-zA-Z'-,.?& ]/g, '');
        }  
        if(catchTimestampFirebase != reCatchesLedger[updateCatchIndex].catch_date) {
            catchData['catch_date'] = catchTimestampFirebase;
        }  
        if(event.target.location_string.value != reCatchesLedger[updateCatchIndex].location_string) {
            catchData['location_string'] = event.target.location_string.value.replace(/[^0-9a-zA-Z'-,.()/;:"&+_ ]/g, '');
        }             
        if((event.target.catch_latitude.value != reCatchesLedger[updateCatchIndex].location.latitude) || (event.target.catch_longitude.value != reCatchesLedger[updateCatchIndex].location.longitude)) {
            catchData['location'] = new firebase.firestore.GeoPoint(parseFloat(event.target.catch_latitude.value.replace(/[^0-9.-]/g, '')), parseFloat(event.target.catch_longitude.value.replace(/[^0-9.-]/g, '')))
        }
        if(event.target.location_area.value != reCatchesLedger[updateCatchIndex].location_area) {
            catchData['location_area'] = event.target.location_area.value.replace(/[^0-9a-zA-Z'-,.()/;:"&+_ ]/g, '');
        }
        if(event.target.location_city.value != reCatchesLedger[updateCatchIndex].location_city) {
            catchData['location_city'] = event.target.location_city.value.replace(/[^0-9a-zA-Z'-,.()/;:"&+_ ]/g, '');
        } 
        if(event.target.state_string.value != reCatchesLedger[updateCatchIndex].location_state) {
            catchData['location_state'] = event.target.state_string.value.replace(/[^a-zA-Z ]/g, '');
        }
        if(event.target.state_code.value != reCatchesLedger[updateCatchIndex].location_state_code) {
            catchData['location_state_code'] = event.target.state_code.value.replace(/[^a-zA-Z]/g, '');
        }
        if(event.target.catch_country.value != reCatchesLedger[updateCatchIndex].location_country) {
            catchData['location_country'] = event.target.catch_country.value.replace(/[^a-zA-Z-,&+.' ]/g, '');
        }
        if((reCatchesLedger[updateCatchIndex].tag_program) && (event.target.tag_program.value != reCatchesLedger[updateCatchIndex].tag_program.id)) {
            catchData['tag_program'] = firebase.firestore().collection('programs').doc(event.target.tag_program.value);
        }
        else if(!reCatchesLedger[updateCatchIndex].tag_program) {
            catchData['tag_program'] = firebase.firestore().collection('programs').doc(event.target.tag_program.value);
        }        
        if(event.target.tag_number.value != reCatchesLedger[updateCatchIndex].tag_number) {
            catchData['tag_number'] = event.target.tag_number.value.replace(/[^0-9a-zA-Z-+.:@%&#, ]/g, '');
        }
        if(event.target.fish_species.value != reCatchesLedger[updateCatchIndex].fish_species.id) {
            catchData['fish_species'] = firebase.firestore().collection('species').doc(event.target.fish_species.value);
            catchData['species_name'] = allSpecies[speciesIndex].name;
        }
        
        if((reCatchesLedger[updateCatchIndex].length) && (event.target.fish_length.value != reCatchesLedger[updateCatchIndex].length)) {
            catchData['length'] = event.target.fish_length.value.replace(/[^0-9.,]/g, '');
        }
        else if((!reCatchesLedger[updateCatchIndex].length) || (reCatchesLedger[updateCatchIndex].length == null) || (reCatchesLedger[updateCatchIndex].length == 0) || (reCatchesLedger[updateCatchIndex].length == '')) {
            catchData['length'] = event.target.fish_length.value.replace(/[^0-9.,]/g, '');
        }
        if((reCatchesLedger[updateCatchIndex].weight) && (event.target.fish_weight.value != reCatchesLedger[updateCatchIndex].weight)) {
            catchData['weight'] = event.target.fish_weight.value.replace(/[^0-9.,]/g, '');
        }
        else if((!reCatchesLedger[updateCatchIndex].weight) || (reCatchesLedger[updateCatchIndex].weight == null) || (reCatchesLedger[updateCatchIndex].weight == 0) || (reCatchesLedger[updateCatchIndex].weight == '')) {
            catchData['weight'] = event.target.fish_weight.value.replace(/[^0-9.,]/g, '');
        }
        
        if((reCatchesLedger[updateCatchIndex].info) && (event.target.catch_info.value != reCatchesLedger[updateCatchIndex].info)) {
            catchData['info'] = event.target.catch_info.value.replace(/[^0-9a-zA-Z!@%&-=+;:'",.? ]/g, '');
        }
        else if((!reCatchesLedger[updateCatchIndex].info) || (reCatchesLedger[updateCatchIndex].info == '') || (reCatchesLedger[updateCatchIndex].info == null)) {
            catchData['info'] = event.target.catch_info.value.replace(/[^0-9a-zA-Z!@%&-=+;:'",.? ]/g, '');
        }
        
        if(event.target.catch_type.value != reCatchesLedger[updateCatchIndex].recatch) {
            catchData['recatch'] = JSON.parse(event.target.catch_type.value);
        }
        if(event.target.catch_units.value != reCatchesLedger[updateCatchIndex].metric_system) {
            catchData['metric_system'] = JSON.parse(event.target.catch_units.value);
        }
        if(downloadedURL) {
            catchData['image_url'] = downloadedURL;
        }
                
        //Create new catch in firestore
        const catchRef = firebase.firestore().collection('catches').doc(reCatchesLedger[updateCatchIndex].id);
        catchRef
            .update(catchData)
            .then((catchResponse) => {
              alert('The catch has been updated successfully!');
              fetchReCatchesLedger(); 
            })
            .catch((error) => {
              setIsUpdating(false);
              alert('Could not update catch entry. Please try again.');
            });  
    };
    
    const createList = () => {
                   
           const catchListOptions = {
               valueNames: ['image_url', 'name', 'angler', 'location_string', 'tag_number', 'date', 'type'],
               pagination: true,
               indexAsync: true,
               searchColumns: ['name', 'angler', 'location_string', 'tag_number'],
               page: 10
           };    
         
         new List('recatch-list', catchListOptions);
    };
    
    const getUpdateTag = async (catchId, catchIndex) => {
        
        const tagId = reCatchesLedger[catchIndex].tag.id;
        
        firebase
        .firestore()
        .collection('tags')
        .doc(tagId)
        .get()
        .then((tagDoc) => {
            const tagData = tagDoc.data();
            setUpdateTagProgramId(tagData.tag_program.id);
        });  
    };
    
    const fetchAllSpecies = async () => {
          
     firebase
     .firestore()
     .collection('species')
     .orderBy('name', 'asc')
     .onSnapshot(
          querySnapshot => {
              const newSpeciesList = []
              querySnapshot.forEach(doc => {
                  const speciesItem = doc.data();
                  speciesItem.id = doc.id;
                  newSpeciesList.push(speciesItem);
              });
              setAllSpecies(newSpeciesList);
          },
          error => {
              console.log(error);
          }
      )
    };
    
    const fetchTagPrograms = async () => {
          
     firebase
     .firestore()
     .collection('programs')
     .orderBy('created_at', 'desc')
     .onSnapshot(
          querySnapshot => {
              const newProgramsList = []
              querySnapshot.forEach(doc => {
                  const programItem = doc.data();
                  programItem.id = doc.id;
                  
                  const createdAt = programItem && programItem.created_at && programItem.created_at.toDate().toLocaleString('en-US', {year: 'numeric', month: 'short', day: 'numeric'});
                    programItem.created = createdAt;
                    const updatedAt = programItem && programItem.updated_at && programItem.updated_at.toDate().toLocaleString('en-US', {year: 'numeric', month: 'short', day: 'numeric'});
                    programItem.updated = updatedAt;
                  
                  
                  newProgramsList.push(programItem);
              });
              setAllTagPrograms(newProgramsList);
          },
          error => {
              console.log(error);
          }
      )
    };
    
    const fetchReCatchesLedger = () => {
        
          
     firebase
     .firestore()
     .collection('catches')
     .where('recatch', '==', true)
     .orderBy('created_at', 'desc')
     .onSnapshot(
          querySnapshot => {
              const newCatchLedger = []
              querySnapshot.forEach(doc => {
                  const catchItem = doc.data();
                    const catchId = doc.id;
                    catchItem.id = catchId;
                    catchItem.angler = catchItem.user_name;
                    catchItem.user_link = 'user/details/' + catchItem.user.id; 
                    catchItem.date = catchItem && catchItem.catch_date && catchItem.catch_date.toDate().toLocaleString('en-US', {year: 'numeric', month: 'short', day: 'numeric'});
                    catchItem.link = 'catch/details/' + catchId;  
                    catchItem.tag_link = 'tag/details/' + catchItem.tag.id;      
                  
                  newCatchLedger.push(catchItem);
              });
              setReCatchesLedger(newCatchLedger);
              setEditModal(false);
              setIsUploading(false);
              setIsUpdating(false);
              setmodal_list(false);
              setDeleteModal(false);
              setIsDeleting(false);
              setRestoreRecatchModal(false);
              setIsRestoringRecatch(false);
              setFetchedData(true);            
          },
          error => {
              console.log(error);
          }
      )
    };

    useEffect(() => {
        
        if(initialLoad) {
           setInitialLoad(false);
           fetchAllSpecies();
           fetchTagPrograms();
           fetchReCatchesLedger();
         }
         
         if(fetchedData && (reCatchesLedger.length > 0)) {
              setFetchedData(false);
              createList();
            }  
        
    });

    return (
        <React.Fragment>
            <div className="page-content">
                <Container fluid>
                    <CardTitle>Catch Ledger</CardTitle>

                    <Row>
                        <Col lg={12}>
                            <Card>
                                <CardHeader>
                                    <h4 className="card-title mb-0">Re-Catches</h4>
                                </CardHeader>

                                <CardBody>
                                    <div id="recatch-list">
                                        <Row className="g-4 mb-3">
                                            <Col className="col-sm-auto">
                                                <div className="d-flex gap-1">
                                                    <Button color="success" className="add-btn" disabled onClick={() => tog_list()} id="create-btn"><i className="ri-add-line align-bottom me-1"></i> Add Re-Catch</Button>
                                                </div>
                                            </Col>
                                            <Col className="col-sm">
                                                <div className="d-flex justify-content-sm-end">
                                                    <div className="search-box ms-2">
                                                        <input type="search" className="fuzzy-search form-control" placeholder="Search Catches..." />
                                                    </div>
                                                </div>
                                            </Col>
                                        </Row>

                                        <div className="table-responsive table-card mt-3 mb-1">
                                        {!isUpdating && !isDeleting &&
                                            <table className="table align-middle table-nowrap" id="recatch-table">
                                                <thead className="table-light">
                                                    <tr>
                                                        <th scope="col" style={{ width: "50px" }}>View</th>
                                                        <th data-sort="image_url">Image</th>
                                                        <th className="sort" data-sort="name">Catch Name</th>
                                                        <th className="sort" data-sort="angler">Angler Name</th>
                                                        <th className="sort" data-sort="location_string">Catch Location</th>
                                                        <th className="sort" data-sort="tag_number">Tag Number</th>
                                                        <th className="sort" data-sort="date" data-default-order='desc'>Catch Date</th>
                                                        <th className="sort" data-sort="type">Type</th>
                                                        <th className="sort" data-sort="action">Actions</th>
                                                    </tr>
                                                </thead>
                                                <tbody className="list form-check-all">
                                                
                                                
                                                    {(reCatchesLedger.length > 0) && reCatchesLedger.map((catchItem, index) => ( 
                                                    <tr key={index}>
                                                        <th scope="row">
                                                            <a className="btn btn-sm btn-success edit-item-btn" href={catchItem.link}><i className="mdi mdi-eye-outline"></i></a>
                                                        </th>
                                                        <td className="image_url">
                                                            {catchItem.image_url &&<img className="avatar-sm rounded" alt={catchItem.name} src={catchItem.image_url} />}
                                                        </td>
                                                        <td className="name">{catchItem.name}</td>
                                                        <td><a href={catchItem.user_link} className="angler fw-medium link-primary">{catchItem.angler}</a></td>
                                                        <td className="location_string">{catchItem.location_string}</td>
                                                        <td><a href={catchItem.tag_link} className="tag_number fw-medium link-primary">#{catchItem.tag_number}</a></td>
                                                        <td className="date">{catchItem.date}</td>
                                                        <td className="type">
                                                            {catchItem && catchItem.recatch &&
                                                                <span className="badge badge-soft-warning text-uppercase">Recatch</span>
                                                            }
                                                            {catchItem && !catchItem.recatch &&
                                                                <span className="badge badge-soft-success text-uppercase">Catch</span>
                                                            }
                                                        </td>
                                                        <td>
                                                            <div className="d-flex gap-2">
                                                                <div className="edit">
                                                                    <button className="btn btn-sm btn-warning edit-item-btn" onClick={() => toggleEditModal(catchItem.id, index)} data-bs-toggle="modal" data-bs-target="#showModal"><i className="mdi mdi-square-edit-outline"></i></button>
                                                                </div>
                                                                
                                                                {!catchItem.deleted &&
                                                                <div className="remove">
                                                                    <button className="btn btn-sm btn-danger remove-item-btn" onClick={() => toggleDeleteModal(catchItem.id, index)} data-bs-toggle="modal" data-bs-target="#deleteRecordModal"><i className="mdi mdi-delete-outline"></i></button>
                                                                </div>
                                                                }
                                                                {catchItem.deleted &&
                                                                <div className="remove">
                                                                    <button className="btn btn-sm btn-success remove-item-btn" onClick={() => toggleRestoreRecatchModal(catchItem.id, index)} data-bs-toggle="modal" style={{ backgroundColor: "green" }} data-bs-target="#restoreRecordModal"><i className="mdi mdi-history"></i></button>
                                                                </div>
                                                                }
                                                            </div>
                                                        </td>
                                                    </tr>
                                                    ))}
                                                    
                                                    
                                                </tbody>
                                            </table>}
                                            
                                            
                                            {reCatchesLedger.length == 0 &&
                                            <div className="noresult">
                                                <div className="text-center">
                                                    <h5 className="mt-2">No Catches Found!</h5>
                                                    <p className="text-muted mb-0">We could not find any re-catches.</p>
                                                </div>
                                            </div>
                                            }
                                        </div>

                                        <div className="d-flex justify-content-end">
                                            <div className="pagination-wrap hstack gap-2">
                                                {/* <Link className="page-item pagination-prev disabled" to="#">
                                                    Previous
                                                </Link> */}
                                                <ul className="pagination listjs-pagination mb-0"></ul>
                                                {/* <Link className="page-item pagination-next" to="#">
                                                    Next
                                                </Link> */}
                                            </div>
                                        </div>
                                    </div>
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                    

                    
                </Container>
            </div>

            {/* Edit Modal */}
            <Modal isOpen={editModal} toggle={() => { toggleEditModal(); }} centered >
                <ModalHeader className="bg-light p-3" id="editModal" toggle={() => { toggleEditModal(); }}> Edit Catch </ModalHeader>
                
                {!isUpdating && reCatchesLedger[updateCatchIndex] &&
                <form className="tablelist-form" onSubmit={submitUpdateForm}>
                    <ModalBody>            
                        <div className="mb-3">
                            <label htmlFor="name-field" className="form-label">Catch Name*</label>
                            <input type="text" name="catch_name" id="name-field" className="form-control" placeholder="Enter Name" required defaultValue={reCatchesLedger[updateCatchIndex].name}/>
                        </div>
                        
                        <div className="mb-3">
                            <label htmlFor="date-field" className="form-label">Catch Date*</label>
                            <Flatpickr
                                className="form-control"
                                name="catch_datetime"
                                options={{
                                    enableTime: true,
                                    dateFormat: "Y-m-d H:i",
                                    defaultDate: reCatchesLedger[updateCatchIndex].catch_date.toDate()
                                }}
                                placeholder="Select Date & Time"
                            />
                        </div>
                        
                        <div className="mb-3">
                            <label htmlFor="image-field" className="form-label">Image File*</label>
                            <tr>
                            <td><img className="avatar-sm rounded" alt={reCatchesLedger[updateCatchIndex].name} src={reCatchesLedger[updateCatchIndex].image_url}/></td>
                            <td><input type="file" name="image_link" id="image-field" className="form-control" onChange={(e) => { setUpdatedImage(e.target.files[0]) }} style={{marginLeft: 20, width: "100%"}}/></td>
                            </tr>
                        </div>
            
                        <div className="mb-3">
                            <label htmlFor="locationstring-field" className="form-label">Full Catch Location*</label>
                            <input type="text" id="locationstring-field" className="form-control" name="location_string" placeholder="Toms River" required defaultValue={reCatchesLedger[updateCatchIndex].location_string}/>
                        </div>
            
                        <div className="mb-3"><tr>
                            <td>
                            <label htmlFor="latitude-field" className="form-label">Latitude*</label>
                            <input type="number" id="latitude-field" className="form-control" placeholder="Enter Latitude*" name="catch_latitude" step="any" required defaultValue={reCatchesLedger[updateCatchIndex].location.latitude} />
                            </td>
                            <td>
                            <label htmlFor="longitude-field" className="form-label">Longitude*</label>
                            <input type="number" id="longitude-field" className="form-control" placeholder="Enter Longitude*" name="catch_longitude" step="any" required defaultValue={reCatchesLedger[updateCatchIndex].location.longitude} />
                            </td>
                        </tr>
                        </div>
                        
                        <div className="mb-3">
                            <tr>
                                <td>
                                    <label htmlFor="locationarea-field" className="form-label">Catch Location Area*</label>
                                    <input type="text" id="locationarea-field" className="form-control" name="location_area" placeholder="Toms River" required defaultValue={reCatchesLedger[updateCatchIndex].location_area}/>
                                </td>
                                <td>
                                    <label htmlFor="locationcity-field" className="form-label">Catch Location City*</label>
                                    <input type="text" id="locationcity-field" className="form-control" name="location_city" placeholder="Atlantic City" required defaultValue={reCatchesLedger[updateCatchIndex].location_city}/>
                                </td>
                            </tr>
                        </div>
                        
                        <div className="mb-3">
                            <tr>
                                <td>
                                    <label htmlFor="state-field" className="form-label">Catch State*</label>
                                    <input type="text" id="state-field" className="form-control" name="state_string" placeholder="New York" required defaultValue={reCatchesLedger[updateCatchIndex].location_state}/>
                                </td>
                                <td>
                                    <label htmlFor="statecode-field" className="form-label">State Code*</label>
                                    <input type="text" id="statecode-field" className="form-control" name="state_code" placeholder="NY" required maxLength={2} defaultValue={reCatchesLedger[updateCatchIndex].location_state_code}/>
                                </td>
                            </tr>
                        </div>
                    
                        <div className="mb-3">
                            <label htmlFor="country-field" className="form-label">Catch Country*</label>
                            <select className="form-control" data-trigger id="country-field" required name="catch_country" defaultValue={reCatchesLedger[updateCatchIndex].location_country}>
                                <option value="">Select Catch Country</option>
                                <option value="USA">United States</option>
                                <option value="CAN">Canada</option>
                            </select>
                        </div>
                        
                        {updateTagProgramId &&
                        <div className="mb-3">
                            <label htmlFor="program-field" className="form-label">Tagging Program*</label>
                            <select className="form-control" data-trigger name="tag_program" id="program-field" required defaultValue={updateTagProgramId}>
                                <option value="">Select Tag Program*</option>
                                {allTagPrograms.map(({ program_name, id }, index) => <option value={id} >{program_name}</option>)}
                            </select>
                        </div> 
                        }
            
                        <div className="mb-3">
                            <label htmlFor="tag-field" className="form-label">Tag Number*</label>
                            <input type="text" id="tag-field" name="tag_number" className="form-control" placeholder="Enter Tag Number*" maxLength={15} required defaultValue={reCatchesLedger[updateCatchIndex].tag_number} />
                        </div>
            
                        <div className="mb-3">
                            <label htmlFor="species-field" className="form-label">Fish Species*</label>
                            <select className="form-control" data-trigger name="fish_species" id="species-field" required defaultValue={reCatchesLedger[updateCatchIndex].fish_species.id}>
                                <option value="">Select Fish Species*</option>
                                {allSpecies.map(({ name, id }, index) => <option value={id} >{name}</option>)}
                            </select>
                        </div>   
                        
                        <div className="mb-3"><tr>
                            <td>
                            {reCatchesLedger[updateCatchIndex].metric_system && <label htmlFor="length-field" className="form-label">Fish Length (CM)</label>}
                              {!reCatchesLedger[updateCatchIndex].metric_system && <label htmlFor="length-field" className="form-label">Fish Length (IN)</label>}
                            <input type="number" id="length-field" className="form-control" placeholder="Enter Fish Length" defaultValue={reCatchesLedger[updateCatchIndex].length} name="fish_length"/>
                            </td>
                            <td>
                            {reCatchesLedger[updateCatchIndex].metric_system && <label htmlFor="weight-field" className="form-label">Fish Weight (KG)</label>}
                              {!reCatchesLedger[updateCatchIndex].metric_system && <label htmlFor="weight-field" className="form-label">Fish Weight (LB)</label>}
                            <input type="number" id="weight-field" className="form-control" placeholder="Enter Fish Weight" defaultValue={reCatchesLedger[updateCatchIndex].weight} name="fish_weight"/>
                            </td>
                        </tr></div>
                        
                        <div className="mb-3">
                            <label htmlFor="catchinfo-field" className="form-label">Catch Information</label>
                            <input type="text" id="catchinfo-field" className="form-control" placeholder="Catch Information" defaultValue={reCatchesLedger[updateCatchIndex].info} name="catch_info"/>
                        </div>
            
                        <div className="mb-3"><tr>
                            <td>
                            <label htmlFor="status-field" className="form-label">Catch Type*</label>
                            <select className="form-control" data-trigger name="catch_type" id="status-field" required defaultValue={reCatchesLedger[updateCatchIndex].recatch}>
                                <option value="">Select Catch Type*</option>
                                <option value="false">New Catch</option>
                                <option value="true">Re-Catch</option>
                            </select>
                            </td>
                            <td>
                            <label htmlFor="units-field" className="form-label">Unit System*</label>
                            <select className="form-control" data-trigger name="catch_units" id="units-field" required defaultValue={reCatchesLedger[updateCatchIndex].metric_system}>
                                <option value="">Select Units System*</option>
                                <option value="true">Metric System</option>
                                <option value="false">Imperial System</option>
                            </select>
                            </td>
                        </tr>
                        </div>
                        
                    </ModalBody>
                    <ModalFooter>
                        <div className="hstack gap-2 justify-content-end">
                            <button type="button" className="btn btn-light" onClick={() => toggleEditModal() }>Close</button>
                            <button type="submit" className="btn btn-success" id="add-btn">Update</button>
                        </div>
                    </ModalFooter>
                </form>
                }
                
                <ModalBody>
                    <ThreeCircles
                      visible={isUpdating}
                      height="100"
                      width="100"
                      color="#4192C3"
                      ariaLabel="three-circles-loading"
                      wrapperStyle={{style:"margin: 0px auto"}}
                      wrapperClass="justify-content-center"
                      />
                </ModalBody>
            </Modal>

            {/* Add Modal */}
            <Modal isOpen={modal_list} toggle={() => { tog_list(); }} centered >
                <ModalHeader className="bg-light p-3" id="exampleModalLabel" toggle={() => { tog_list(); }}> Add Catch </ModalHeader>
                <form className="tablelist-form">
                    <ModalBody>
                        <div className="mb-3" id="modal-id" style={{ display: "none" }}>
                            <label htmlFor="id-field" className="form-label">ID</label>
                            <input type="text" id="id-field" className="form-control" placeholder="ID" readOnly />
                        </div>

                        <div className="mb-3">
                            <label htmlFor="name-field" className="form-label">Catch Name*</label>
                            <input type="text" id="name-field" className="form-control" placeholder="Enter Name" required />
                        </div>
                        
                        <div className="mb-3">
                            <label htmlFor="date-field" className="form-label">Catch Date</label>
                            <Flatpickr
                                className="form-control"
                                options={{
                                    enableTime: true,
                                    dateFormat: "d M, Y H:i"
                                }}
                                placeholder="Select Date & Time"
                            />
                        </div>

                        <div className="mb-3">
                            <label htmlFor="latitude-field" className="form-label">Latitude</label>
                            <input type="number" id="latitude-field" className="form-control" placeholder="Enter Latitude*" required />
                        </div>
                        
                        <div className="mb-3">
                            <label htmlFor="longitude-field" className="form-label">Longitude</label>
                            <input type="number" id="longitude-field" className="form-control" placeholder="Enter Longitude*" required />
                        </div>

                        <div className="mb-3">
                            <label htmlFor="tag-field" className="form-label">Tag Number</label>
                            <input type="number" id="tag-field" className="form-control" placeholder="Enter Tag Number*" required />
                        </div>

                        <div className="mb-3">
                            <label htmlFor="species-field" className="form-label">Fish Species</label>
                            <select className="form-control" data-trigger name="species-field" id="species-field" >
                                <option value="">Select Fish Species*</option>
                            </select>
                        </div>   
                        
                        <div className="mb-3">
                            <label htmlFor="length-field" className="form-label">Fish Length (CM)</label>
                            <input type="number" id="length-field" className="form-control" placeholder="Enter Fish Length" required />
                        </div>
                        
                        <div className="mb-3">
                            <label htmlFor="weight-field" className="form-label">Fish Weight (KG)</label>
                            <input type="number" id="weight-field" className="form-control" placeholder="Enter Fish Weight*" required />
                        </div>
                        
                        <div className="mb-3">
                            <label htmlFor="program-field" className="form-label">Tagging Program</label>
                            <select className="form-control" data-trigger name="program-field" id="program-field" >
                                <option value="">Select Tag Program*</option>
                            </select>
                        </div>   

                        <div className="mb-3">
                            <label htmlFor="status-field" className="form-label">Type</label>
                            <select className="form-control" data-trigger name="status-field" id="status-field" >
                                <option value="Active">New Catch</option>
                                <option value="Block">Re-Catch</option>
                            </select>
                        </div>
                        
                    </ModalBody>
                    <ModalFooter>
                        <div className="hstack gap-2 justify-content-end">
                            <button type="button" className="btn btn-light" onClick={() => setmodal_list(false)}>Close</button>
                            <button type="submit" className="btn btn-success disabled" id="add-btn">Add Catch</button>
                            {/* <button type="button" className="btn btn-success" id="edit-btn">Update</button> */}
                        </div>
                    </ModalFooter>
                </form>
            </Modal>

            {/* Remove Modal */}
            <Modal isOpen={deleteModal} toggle={() => { toggleDeleteModal(); }} className="modal zoomIn" id="deleteRecordModal" centered >
            
                {!isDeleting &&
                <ModalBody>
                    <div className="mt-2 text-center">
                                <div className="mt-4 pt-2 fs-15 mx-4 mx-sm-5">
                                    <h4>Delete Re-Catch Data</h4>
                                    <p className="text-muted mx-4 mb-0">Are you sure you want to delete this re-catch?</p>
                                </div>
                    </div>
                    <div className="d-flex gap-2 justify-content-center mt-4 mb-2">
                        <button type="button" className="btn w-sm btn-light" onClick={() => toggleDeleteModal()}>Cancel</button>
                        <button type="button" className="btn w-sm btn-danger" id="delete-record" onClick={deleteCatch}>Delete</button>
                    </div>
                </ModalBody>
                }
                    
                    
                    <ModalBody>
                        <ThreeCircles
                          visible={isDeleting}
                          height="100"
                          width="100"
                          color="#ff3d60"
                          ariaLabel="three-circles-loading"
                          wrapperStyle={{style:"margin: 0px auto"}}
                          wrapperClass="justify-content-center"
                          />
                    </ModalBody>
            </Modal>
            
            {/* Restore Recatch Modal */}
              <Modal isOpen={restoreRecatchModal} toggle={() => { toggleRestoreRecatchModal(); }} className="modal zoomIn" id="restoreRecatchModal" centered >
              
                  {!isRestoringRecatch &&
                  <ModalBody>
                      <div className="mt-2 text-center">
                                  <div className="mt-4 pt-2 fs-15 mx-4 mx-sm-5">
                                      <h4>Restore Re-Catch Data</h4>
                                      <p className="text-muted mx-4 mb-0">Do you want to restore this re-catch?</p>
                                  </div>
                      </div>
                      <div className="d-flex gap-2 justify-content-center mt-4 mb-2">
                          <button type="button" className="btn w-sm btn-light" onClick={() => toggleRestoreRecatchModal()}>Cancel</button>
                          <button type="button" className="btn w-sm btn-success" id="delete-record" onClick={restoreRecatch}>Restore</button>
                      </div>
                  </ModalBody>
                  }
                      
                      
                      <ModalBody>
                          <ThreeCircles
                            visible={isRestoringRecatch}
                            height="100"
                            width="100"
                            color="#4192C3"
                            ariaLabel="three-circles-loading"
                            wrapperStyle={{style:"margin: 0px auto"}}
                            wrapperClass="justify-content-center"
                            />
                      </ModalBody>
              </Modal>
            
        </React.Fragment>
    );
};

export default ReCatches;
