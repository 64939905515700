import React, { useRef, useState, useEffect } from 'react'
import { Button, Card, CardBody, CardTitle, CardHeader, Col, Container, ListGroup, ListGroupItem, Modal, ModalBody, ModalFooter, Row, ModalHeader } from 'reactstrap';
import Breadcrumbs from "../../components/Common/Breadcrumb";
import SimpleBar from 'simplebar-react';
import { Link } from 'react-router-dom';
import List from 'list.js';
import { ThreeCircles } from 'react-loader-spinner'
import Flatpickr from "react-flatpickr";

import firebase from "firebase/compat/app";
import "firebase/firestore";
import "firebase/auth";
import 'firebase/storage';
import {serverTimestamp} from 'firebase/firestore';
import { getDownloadURL, uploadBytesResumable, uploadBytes, getStorage, ref } from "firebase/storage";

const FishSpecies = () => {
    
    const storage = getStorage();
    
    const [initialLoad, setInitialLoad] = useState(true);
    const [isUploading, setIsUploading] = useState(false);
    const [isUpdating, setIsUpdating] = useState(false);
    const [updateSpeciesId, setUpdateSpeciesId] = useState('');
    const [updateSpeciesIndex, setUpdateSpeciesIndex] = useState(null);
    const [deleteSpeciesId, setDeleteSpeciesId] = useState('');
    const [deleteSpeciesIndex, setDeleteSpeciesIndex] = useState(null);
    const [isDeleting, setIsDeleting] = useState(false);
    const [fetchedSpecies, setFetchedSpecies] = useState(false);
    const [allSpeciesList, setAllSpeciesList] = useState([]);
    const [speciesImage, setSpeciesImage] = useState(null);
    const [updatedImage, setUpdatedImage] = useState(null);
    
    const [restoreSpeciesId, setRestoreSpeciesId] = useState('');
    const [restoreSpeciesIndex, setRestoreSpeciesIndex] = useState(null);
    const [isRestoring, setIsRestoring] = useState(false);
        
    //Create species data
    
    document.title = "Fish Species | Fish-Trak - Internal Management Dashboard";

    const [editModal, setEditModal] = useState(false);
    function toggleEditModal(speciesId, speciesIndex) {
        
        if(editModal) {
            setUpdateSpeciesId('');
            setUpdateSpeciesIndex(null);
        }
        else {
            setUpdateSpeciesId(speciesId);
            setUpdateSpeciesIndex(speciesIndex);
        }
        
        setEditModal(!editModal);
    }

    const [modal_list, setmodal_list] = useState(false);
    function tog_list() {
        setmodal_list(!modal_list);
    }

    const [deleteModal, setDeleteModal] = useState(false);
    function toggleDeleteModal(speciesId, speciesIndex) {
        
        if(deleteModal) {
            setDeleteSpeciesId('');
            setDeleteSpeciesIndex(null);
        }
        else {
            setDeleteSpeciesId(speciesId);
            setDeleteSpeciesIndex(speciesIndex);
        }
        
        setDeleteModal(!deleteModal);
        
    }
    
    const [restoreModal, setRestoreModal] = useState(false);
    function toggleRestoreModal(speciesId, speciesIndex) {
        
        if(deleteModal) {
            setRestoreSpeciesId('');
            setRestoreSpeciesIndex(null);
        }
        else {
            setRestoreSpeciesId(speciesId);
            setRestoreSpeciesIndex(speciesIndex);
        }
        
        setRestoreModal(!restoreModal);
        
    }
    
    const submitCreateForm = async (event) => {
    
        setIsUploading(true);
        event.preventDefault();
        
        uploadImage(event);
        
    }
    
    const uploadImage = async (event) => {
        
        setIsUploading(true);
        event.preventDefault();
      
      const imageFileName = '/fish-species/' + event.target.species_name.value.replace(/[^0-9a-zA-Z-]/g, '-');
           
      const storageRef = ref(storage, imageFileName);
    
      const uploadTask = uploadBytesResumable(storageRef, speciesImage);
      uploadTask.on(
        "state_changed",
        (snapshot) => {
          const progress =
            (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
          console.log("upload is" + progress + "% done");
          switch (snapshot.state) {
            case "paused":
              console.log("Upload paused");
              break;
            case "running":
              console.log("Upload running");
              break;
            default:
              break;
          }
        },
        (error) => {
          console.log(error);
        },
        () => {
          getDownloadURL(uploadTask.snapshot.ref).then((downloadedURL) => {
            createNewSpecies(event, downloadedURL);
          });
        }
      );
    };
    
    const createNewSpecies = async (event, downloadedURL) => {
                
      //Create array of catch data
        const speciesData = {
          created_at: serverTimestamp(),
          updated_at: serverTimestamp(),
          deleted: false,
          name: event.target.species_name.value.replace(/[^0-9a-zA-Z'-,.?& ]/g, ''),
          fish_type: event.target.fish_type.value,
          image_url: downloadedURL
        };
                
        //Create new catch in firestore
        const speciesRef = firebase.firestore().collection('species');
        speciesRef
            .add(speciesData)
            .then((speciesResponse) => {
              alert('The new species has been created successfully!');
              fetchAllSpecies(); 
            })
            .catch((error) => {
              setIsUploading(false);
              alert('Could not create species entry. Please try again.');
            });  
    };
    
    const submitUpdateForm = async (event) => {
    
        setIsUploading(true);
        event.preventDefault();
        
        setIsUpdating(true);
        
        if(updatedImage) {
            updateImage(event);
        }
        else {
            updateSpecies(event);
        }
    
    }
    
    const updateImage = async (event) => {
        
        
        event.preventDefault();
      
      const imageFileName = '/fish-species/' + event.target.species_name.value.replace(/[^0-9a-zA-Z-]/g, '-');
           
      const storageRef = ref(storage, imageFileName);
    
      const uploadTask = uploadBytesResumable(storageRef, updatedImage);
      uploadTask.on(
        "state_changed",
        (snapshot) => {
          const progress =
            (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
          console.log("upload is" + progress + "% done");
          switch (snapshot.state) {
            case "paused":
              console.log("Upload paused");
              break;
            case "running":
              console.log("Upload running");
              break;
            default:
              break;
          }
        },
        (error) => {
          console.log(error);
          setIsUpdating(false);
            alert('Could not update species image. Please try again.');
        },
        () => {
          getDownloadURL(uploadTask.snapshot.ref).then((downloadedURL) => {
            updateSpecies(event, downloadedURL);
          });
        }
      );
    };
    
    const updateSpecies = async (event, downloadedURL) => {
                
      //Create array of catch data
        const speciesData = {
          updated_at: serverTimestamp(),
        };
        
        if(event.target.species_name.value != allSpeciesList[updateSpeciesIndex].name) {
            speciesData['name'] = event.target.species_name.value.replace(/[^0-9a-zA-Z'-,.?& ]/g, '');
        }
        if(event.target.species_name.value != allSpeciesList[updateSpeciesIndex].type) {
            speciesData['fish_type'] = event.target.fish_type.value;
        }
        if(downloadedURL) {
            speciesData['image_url'] = downloadedURL;
        }
                
        //Create new catch in firestore
        const speciesRef = firebase.firestore().collection('species').doc(allSpeciesList[updateSpeciesIndex].id);
        speciesRef
            .update(speciesData)
            .then((speciesResponse) => {
              alert('The species has been updated successfully!');
              fetchAllSpecies(); 
            })
            .catch((error) => {
              setIsUpdating(false);
              alert('Could not update species entry. Please try again.');
            });  
    };
    
    const deleteSpecies = async () => {
        
        setIsDeleting(true);
                
        //Delete species from database
        const speciesRef = firebase.firestore().collection('species').doc(deleteSpeciesId);  
        speciesRef
          .update({
              updated_at: serverTimestamp(),
            deleted: true
          })
          .then(() => {
            fetchAllSpecies();
            alert('The species had been deleted successfully!');
            setDeleteSpeciesId('');
            setDeleteSpeciesIndex(null);
             
          })
          .catch((error) => {
            setIsDeleting(false);
            alert('Could not delete species entry. Please try again.');
          });
    };
    
    const restoreSpecies = async () => {
        
        setIsRestoring(true);
                
        //Restore species in database
        const speciesRef = firebase.firestore().collection('species').doc(restoreSpeciesId);  
        speciesRef
          .update({
              updated_at: serverTimestamp(),
              deleted: false
            })
          .then(() => {
            fetchAllSpecies();
            alert('The species had been restored successfully!');
            setRestoreSpeciesId('');
            setRestoreSpeciesIndex(null);
             
          })
          .catch((error) => {
            setIsRestoring(false);
            alert('Could not restore species entry. Please try again.');
          });
    };
    
    const createList =  () => {
        const tagsListOptions = {
             valueNames: ['id', 'name', { attr: 'src', name: 'image' }, 'created', 'updated', 'type', { attr: 'href', name: 'link' }],
             pagination: true,
             indexAsync: true,
             searchColumns: ['name'],
             page: 10,
         };
         new List('species-list', tagsListOptions);
    }
    
    const fetchAllSpecies = async () => {

     firebase
     .firestore()
     .collection('species')
     .orderBy('name', 'asc')
     .onSnapshot(
          querySnapshot => {
              const newSpeciesList = []
              querySnapshot.forEach(doc => {
                  const speciesItem = doc.data();
                  const speciesId = doc.id;
                  
                  speciesItem.id = speciesId;
                  speciesItem.name = speciesItem.name;
                  speciesItem.image = speciesItem.image_url;
                  speciesItem.type = speciesItem.fish_type;
                  speciesItem.link = 'species/details/' + speciesId;
                  
                  const createdAt = speciesItem && speciesItem.created_at && speciesItem.created_at.toDate().toLocaleString('en-US', {year: 'numeric', month: 'short', day: 'numeric'});
                  speciesItem.created = createdAt;
                  const updatedAt = speciesItem && speciesItem.updated_at && speciesItem.updated_at.toDate().toLocaleString('en-US', {year: 'numeric', month: 'short', day: 'numeric'});
                  speciesItem.updated = updatedAt;
                  
                  newSpeciesList.push(speciesItem);
              });
              setAllSpeciesList(newSpeciesList);
              setmodal_list(false);  
              setDeleteModal(false);
              setEditModal(false);
              setRestoreModal(false);
              setIsUploading(false);
              setIsUpdating(false);
              setIsDeleting(false);
              setIsRestoring(false);
              setFetchedSpecies(true);             
          },
          error => {
              console.log(error)
          }
      )
    };
    


    useEffect(() => {        
         
        if(initialLoad) {
           setInitialLoad(false);
           fetchAllSpecies();
         }
         
         if(fetchedSpecies && (allSpeciesList.length > 0)) {
             setFetchedSpecies(false);
             createList();
         }         
        
    });

    return (
        <React.Fragment>
            <div className="page-content">
                <Container fluid>
                    <CardTitle>Fish Species</CardTitle>

                    <Row>
                        <Col lg={12}>
                            <Card>
                                <CardHeader>
                                    <h4 className="card-title mb-0">All Species</h4>
                                </CardHeader>

                                <CardBody>
                                    <div id="species-list">
                                        <Row className="g-4 mb-3">
                                            <Col className="col-sm-auto">
                                                <div className="d-flex gap-1">
                                                    <Button color="success" className="add-btn" onClick={() => tog_list()} id="create-btn"><i className="ri-add-line align-bottom me-1"></i> Add Species</Button>
                                                </div>
                                            </Col>
                                            <Col className="col-sm">
                                                <div className="d-flex justify-content-sm-end">
                                                    <div className="search-box ms-2">
                                                        <input type="search" className="fuzzy-search form-control" placeholder="Search Species..." />
                                                    </div>
                                                </div>
                                            </Col>
                                        </Row>

                                        <div className="table-responsive table-card mt-3 mb-1">
                                        {!isUpdating && !isDeleting &&
                                            <table className="table align-middle table-nowrap" id="species-table">
                                                <thead className="table-light">
                                                    <tr>
                                                        <th scope="col" style={{ width: "50px" }}>View</th>
                                                        <th data-sort="image">Image</th>
                                                        <th className="sort" data-sort="name">Species Name</th>
                                                        <th className="sort" data-sort="created" data-default-order='desc'>Created Date</th>
                                                        <th className="sort" data-sort="updated" data-default-order='desc'>Updated Date</th>
                                                        <th className="sort" data-sort="type">Fish Type</th>
                                                        <th className="sort" data-sort="action">Actions</th>
                                                    </tr>
                                                </thead>
                                                <tbody className="list form-check-all">
                                                
                                                
                                                {(allSpeciesList.length > 0) && allSpeciesList.map((species, index) => ( 
                                                    <tr key={index}>
                                                        <th scope="row">
                                                            <a className="btn btn-sm btn-success edit-item-btn" href={species.link}><i className="mdi mdi-eye-outline"></i></a>
                                                        </th>
                                                        <td className="image"><img className="avatar-sm rounded" alt={species.name} src={species.image} /></td>
                                                        <td className="name">{species.name}</td>
                                                        <td className="created">{species.created}</td>
                                                        <td className="updated">{species.updated}</td>
                                                        <td><span className="type badge badge-soft-success text-uppercase">{species.type}</span></td>
                                                        <td className="action">
                                                            <div className="d-flex gap-2">
                                                                <div className="edit">
                                                                    <button className="btn btn-sm btn-warning edit-item-btn"
                                                                        data-bs-toggle="modal" data-bs-target="#showModal" onClick={() => toggleEditModal(species.id, index)}><i className="mdi mdi-square-edit-outline"></i></button>
                                                                </div>
                                                                
                                                                {!species.deleted &&
                                                                <div className="remove">
                                                                    <button className="btn btn-sm btn-danger remove-item-btn" data-bs-toggle="modal" data-bs-target="#deleteRecordModal" onClick={() => toggleDeleteModal(species.id, index)}><i className="mdi mdi-delete-outline"></i></button>
                                                                </div>
                                                                }
                                                                
                                                                {species.deleted &&
                                                                <div className="remove">
                                                                    <button className="btn btn-sm btn-success remove-item-btn" data-bs-toggle="modal" data-bs-target="#restoreRecordModal" onClick={() => toggleRestoreModal(species.id, index)} style={{ backgroundColor: "green" }}><i className="mdi mdi-history"></i></button>
                                                                </div>
                                                                }
                                                                
                                                                
                                                            </div>
                                                        </td>
                                                    </tr>
                                                ))}
                                                </tbody>
                                            </table>}

                                            {allSpeciesList.length == 0 &&
                                            <div className="noresult">
                                                <div className="text-center">
                                                    <h5 className="mt-2">No Fish Species Found!</h5>
                                                    <p className="text-muted mb-0">We could not find any fish species.</p>
                                                </div>
                                            </div>
                                            }
                                        </div>

                                        <div className="d-flex justify-content-end">
                                            <div className="pagination-wrap hstack gap-2">
                                                <ul className="pagination listjs-pagination mb-0"></ul>
                                            </div>
                                        </div>
                                    </div>
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                    

                    
                </Container>
            </div>
            
            {/* Edit Modal */}
            <Modal isOpen={editModal} toggle={() => { toggleEditModal(); }} centered >
                <ModalHeader className="bg-light p-3" id="editModal" toggle={() => { toggleEditModal() }}> Edit Fish Species </ModalHeader>
                
                {!isUpdating && allSpeciesList[updateSpeciesIndex] &&
                <form className="tablelist-form" onSubmit={submitUpdateForm}>
                    <ModalBody>
                        <div className="mb-3">
                            <label htmlFor="name-field" className="form-label">Species Name*</label>
                            <input type="text" id="name-field" className="form-control" placeholder="Atlantic Salmon" name="species_name" defaultValue={allSpeciesList[updateSpeciesIndex].name} required />
                        </div>
                        
                        <div className="mb-3">
                            <label htmlFor="image-field" className="form-label">Image File*</label>
                            <tr>
                            <td><img className="avatar-sm rounded" alt={allSpeciesList[updateSpeciesIndex].name} src={allSpeciesList[updateSpeciesIndex].image}/></td>
                            <td><input type="file" name="image_link" id="image-field" className="form-control" onChange={(e) => { setUpdatedImage(e.target.files[0]) }} style={{marginLeft: 20, width: "100%"}}/></td>
                            </tr>
                        </div>
                        
                        <div className="mb-3">
                            <label htmlFor="type-field" className="form-label">Fish Type*</label>
                            <select className="form-control" data-trigger name="fish_type" id="type-field" required defaultValue={allSpeciesList[updateSpeciesIndex].type} >
                                <option value="">Select Fish Type</option>
                                <option value="Freshwater">Freshwater</option>
                                <option value="Water">Water</option>
                                <option value="Saltwater">Saltwater</option>
                            </select>
                        </div>   
                    </ModalBody>
                    <ModalFooter>
                        <div className="hstack gap-2 justify-content-end">
                            <button type="button" className="btn btn-light" onClick={() => toggleEditModal()}>Cancel</button>
                            <button type="submit" className="btn btn-success" id="add-btn">Update</button>
                            {/* <button type="button" className="btn btn-success" id="edit-btn">Update</button> */}
                        </div>
                    </ModalFooter>
                </form>
                }
                
                <ModalBody>
                    <ThreeCircles
                      visible={isUpdating}
                      height="100"
                      width="100"
                      color="#4192C3"
                      ariaLabel="three-circles-loading"
                      wrapperStyle={{style:"margin: 0px auto"}}
                      wrapperClass="justify-content-center"
                      />
                </ModalBody>
            </Modal>

            {/* Add Modal */}
            <Modal isOpen={modal_list} toggle={() => { tog_list(); }} centered >
                <ModalHeader className="bg-light p-3" id="exampleModalLabel" toggle={() => { tog_list(); }}> Add Fish Species </ModalHeader>
                
                {!isUploading &&
                <form className="tablelist-form" onSubmit={submitCreateForm}>
                    <ModalBody>
                        <div className="mb-3">
                            <label htmlFor="name-field" className="form-label">Species Name*</label>
                            <input type="text" id="name-field" className="form-control" placeholder="Atlantic Salmon" name="species_name" required />
                        </div>
                        
                        <div className="mb-3">
                            <label htmlFor="image-field" className="form-label">Image File*</label>
                            {/* <input type="text" id="image-field" className="form-control" placeholder="https://fish-trak.com/species.png" name="image_link" value="" required /> */}
                            
                            <input type="file" name="image_link" required id="image-field" className="form-control" onChange={(e) => { setSpeciesImage(e.target.files[0]) }} />
                        </div>
                        
                        <div className="mb-3">
                            <label htmlFor="type-field" className="form-label">Fish Type*</label>
                            <select className="form-control" data-trigger name="fish_type" id="type-field" required >
                                <option value="">Select Fish Type</option>
                                <option value="freshwater">Fresh Water</option>
                                <option value="water">Water</option>
                                <option value="saltwater">Salt Water</option>
                            </select>
                        </div>   
                    </ModalBody>
                    <ModalFooter>
                        <div className="hstack gap-2 justify-content-end">
                            <button type="button" className="btn btn-light" onClick={() => setmodal_list(false)}>Cancel</button>
                            <button type="submit" className="btn btn-success" id="add-btn">Add Species</button>
                            {/* <button type="button" className="btn btn-success" id="edit-btn">Update</button> */}
                        </div>
                    </ModalFooter>
                </form>
                }
                
                <ModalBody>
                    <ThreeCircles
                      visible={isUploading}
                      height="100"
                      width="100"
                      color="#4192C3"
                      ariaLabel="three-circles-loading"
                      wrapperStyle={{style:"margin: 0px auto"}}
                      wrapperClass="justify-content-center"
                      />
                </ModalBody>
            </Modal>

            {/* Remove Modal */}
            <Modal isOpen={deleteModal} toggle={() => { toggleDeleteModal(); }} className="modal zoomIn" id="deleteRecordModal" centered >
            
                {!isDeleting &&
                <ModalBody>
                    <div className="mt-2 text-center">
                        <div className="mt-4 pt-2 fs-15 mx-4 mx-sm-5">
                            <h4>Delete Fish Species</h4>
                            <p className="text-muted mx-4 mb-0">Are you sure you want to delete this species?</p>
                        </div>
                    </div>
                    <div className="d-flex gap-2 justify-content-center mt-4 mb-2">
                        <button type="button" className="btn w-sm btn-light" onClick={() => toggleDeleteModal()}>Cancel</button>
                        <button type="button" className="btn w-sm btn-danger" id="delete-record" onClick={deleteSpecies}>Delete</button>
                    </div>
                </ModalBody>
                }
                    
                    
                    <ModalBody>
                        <ThreeCircles
                          visible={isDeleting}
                          height="100"
                          width="100"
                          color="#ff3d60"
                          ariaLabel="three-circles-loading"
                          wrapperStyle={{style:"margin: 0px auto"}}
                          wrapperClass="justify-content-center"
                          />
                    </ModalBody>
            </Modal>
            
            {/* Restore Modal */}
            <Modal isOpen={restoreModal} toggle={() => { toggleRestoreModal(); }} className="modal zoomIn" id="restoreRecordModal" centered >
            
                {!isRestoring &&
                <ModalBody>
                    <div className="mt-2 text-center">
                        <div className="mt-4 pt-2 fs-15 mx-4 mx-sm-5">
                            <h4>Restore Fish Species</h4>
                            <p className="text-muted mx-4 mb-0">Do you really want to restore this species?</p>
                        </div>
                    </div>
                    <div className="d-flex gap-2 justify-content-center mt-4 mb-2">
                        <button type="button" className="btn w-sm btn-light" onClick={() => toggleRestoreModal()}>Cancel</button>
                        <button type="button" className="btn w-sm btn-success" id="restore-record" onClick={restoreSpecies}>Restore</button>
                    </div>
                </ModalBody>
                }
                    
                    
                    <ModalBody>
                        <ThreeCircles
                          visible={isRestoring}
                          height="100"
                          width="100"
                          color="#4192C3"
                          ariaLabel="three-circles-loading"
                          wrapperStyle={{style:"margin: 0px auto"}}
                          wrapperClass="justify-content-center"
                          />
                    </ModalBody>
            </Modal>
            
        </React.Fragment>
    );
};

export default FishSpecies;
